import React from "react";
import { useDispatch } from "react-redux";
import { addProduct } from "../../Compare/app";

export function AddToComparisionButton({ product }) {
  const dispatch = useDispatch();

  return (
    <button
      onClick={() => dispatch(addProduct(product))}
      style={{
        display: "block",
        marginLeft: "auto",
        marginTop: 24,
        marginRight: 24,
        marginBottom: -40,
        zIndex: 1,
        fontSize: 16,
        position: "relative",
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        color: "#c8102e",
        fontFamily: "'Bebas Neue', sans-serif",
        letterSpacing: "0.02em",
      }}
    >
      Compare
    </button>
  );
}
