import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid/Grid";
import styles from "./styles";
// import Link from "../../Link";

// import AccountRecentOrders from "../AccountRecentOrders";
import AccountPriceList from "../AccountPriceList";
import AccountOffers from "../AccountOffers";
import { isLoggedIn } from "../../../services/auth";
import { isBrowser } from "../../../helpers";

const useStyles = makeStyles((theme) => styles(theme));

const queryGroup = (group = "") => {
  return {
    query: `
      {
        group(id: "${group}", idType: SLUG) {
            id
            groupFields {
              priceList {
                mediaItemUrl
              }
            }
        }
      }
    `,
  };
};

export default function AccountNavWrap(props) {
  const classes = useStyles();

  const [active1, setActive1] = useState("offers");
  const [group, setGroup] = useState(null);
  const [authenticated, setAuthentication] = useState(undefined);
  const [searching, setSearching] = useState(false);
  const [list, setList] = useState(undefined);

  isBrowser && authenticated === undefined && handleAuthentication();

  useEffect(() => {
    if (group && !list) {
      fetchGroup(group);
    }
  }, [group]); //eslint-disable-line

  useEffect(() => {
    if (list && !active1) {
      setActive("prices");
    }
  }, [list]); //eslint-disable-line

  const setActive = (a) => {
    setActive1(a);
  };

  async function handleAuthentication() {
    const response = await isLoggedIn();

    if (response.data.errors) {
      setAuthentication(false);
    } else {
      setAuthentication(response.data.data.user);

      const group = response.data.data.user.userGroup.group.slug;

      if (group) {
        setGroup(group);
      }
    }
  }

  function fetchGroup(group) {
    setSearching(true);
    axios
      .post(`${process.env.GATSBY_ADMIN_URL}graphql`, queryGroup(group), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data.data)
      .then((data) => {
        setList(data.group.groupFields.priceList?.mediaItemUrl);
        setSearching(false);
      });
  }

  return (
    <div className={`account-header ${classes.accountNavWrapper}`}>
      <Grid container spacing={0} direction="row">
        <Grid item xs={12} sm={3}>
          <div className="account-nav">
            {list && (
              <button
                onClick={() => setActive("prices")}
                onKeyDown={() => setActive("prices")}
                open={active1 === "prices"}
              >
                Price List
              </button>
            )}
            <button
              onClick={() => setActive("offers")}
              onKeyDown={() => setActive("offers")}
              open={active1 === "offers"}
            >
              Exclusive Offers
            </button>
          </div>
        </Grid>
        <Grid item xs={12} sm={9}>
          <div className="page-content">
            {list && (
              <div className="price-list" open={active1 === "prices"}>
                <AccountPriceList list={list} />
              </div>
            )}
            {!list && searching && (
              <div className="price-list" open={true}>
                <p>Searching...</p>
              </div>
            )}
            <div className="offers" open={active1 === "offers"}>
              <AccountOffers />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
