import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import styles from "./styles";
// import Link from "../../Link";

import { CoreHeadingBlock } from "../../../components/blocks/CoreHeadingBlock";
import ProductsGrid from "../../Products/Components/Grid";

const useStyles = makeStyles((theme) => styles(theme));

const queryObject = (next = "") => ({
  query: `
    {
        products(first: 50, after: "${next}") {
            edges {
              node {
                id
                title
                link
                product {
                  exclusiveOffers
                  code
                  productGallery {
                    fieldGroupName
                    image
                  }
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
    }
    `,
});

export default function AccountOffers(props) {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [searching, setSearching] = useState(false);

  if (!products.length) {
  }

  React.useEffect(() => {
    if (products.length > 0) return;

    setSearching(true);
    fetchProducts();
  }, [products]); //eslint-disable-line

  function fetchProducts(next = "", prevData = []) {
    axios
      .post(`${process.env.GATSBY_ADMIN_URL}graphql`, queryObject(next), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return {
          products: res.data.data.products.edges.map((node) => node.node),
          next: res.data.data.products.pageInfo.endCursor,
          hasNextPage: res.data.data.products.pageInfo.hasNextPage,
        };
      })
      .then((data) => {
        return {
          products: data.products.filter((product) => {
            return product.product.exclusiveOffers;
          }),
          next: data.next,
          hasNextPage: data.hasNextPage,
        };
      })
      .then((data) => {
        if (data.hasNextPage) {
          fetchProducts(data.next, prevData.concat(data.products));
        } else {
          setProducts(prevData.concat(data.products));
          setSearching(false);
        }
      });
  }

  return (
    <div className={`account-prices ${classes.accountOffers}`}>
      <div className="content">
        <CoreHeadingBlock
          attributes={{
            align: "",
            anchor: "",
            className: "",
            content: "Exclusive Offers",
            level: 2,
            textColor: "primary",
            backgroundColor: "",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
        {searching && <div>Loading...</div>}
        {products.length > 0 && !searching && (
          <div style={{ maxWidth: 610 }}>
            <ProductsGrid products={products} md={6} />
          </div>
        )}
      </div>
      <div className="image-link"></div>
    </div>
  );
}
