export default theme => ({
    accountHeader: {
        position: 'relative',
        margin: '32px 0',
        [theme.breakpoints.up('sm')]: {
            margin: '80px 0 40px',
        },
        '& .profile': {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: 24,
            [theme.breakpoints.up('sm')]: {
                marginBottom: 0,
            },
            '& .info': {
                '& h1': {
                    margin: 0,
                    fontSize: 32,
                    fontWeight: 'normal',
                    fontFamily: 'Roboto Condensed',
                    letterSpacing: '0.008em',
                    lineHeight: '1.5',
                    textTransform: 'capitalize'
                },
                '& p': {
                    fontFamily: 'Overpass',
                    letterSpacing: '0.016em',
                    lineHeight: 1.5,
                    margin: 0,
                    fontSize: 16,
                }
            }
        },
        '& .actions': {
            [theme.breakpoints.up('sm')]: {
                textAlign: 'right',
            },
            '& .button': {
                display: 'table',
                margin: '0 auto 12px',
                backgroundColor: theme.palette.secondary.contrastText,
                color: theme.palette.secondary.main,
                textTransform: 'uppercase',
                fontFamily: theme.headerFont,
                fontSize: 16,
                lineHeight: 1.5,
                borderRadius: 16,
                boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
                textDecoration: 'none',
                padding: '5px 20px',
                transition: 'background-color .3s ease-in-out, color .3s ease-in-out',
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                },
                '& svg': {
                    width: 15,
                    height: 15,
                    verticalAlign: 'middle',
                },
                [theme.breakpoints.up('sm')]: {
                    display: 'inline-block',
                    margin: '0 8px',
                }
            },
            '& .link': {
                display: 'table',
                margin: '0 auto 12px',
                backgroundColor: 'transparent',
                color: theme.palette.secondary.main,
                textTransform: 'uppercase',
                fontFamily: theme.headerFont,
                fontSize: 16,
                lineHeight: 1.5,
                borderRadius: 16,
                border: 'none',
                textDecoration: 'none',
                padding: '5px 20px',
                transition: 'background-color .3s ease-in-out, color .3s ease-in-out',
                '& svg': {
                    width: 20,
                    height: 20,
                    verticalAlign: 'middle',
                    transform: 'scale(1.5)'
                },
                [theme.breakpoints.up('sm')]: {
                    display: 'inline-block',
                    margin: '0 0 12px',
                },
                [theme.breakpoints.up('md')]: {
                    margin: '0',
                }
            }
        }
    },
})