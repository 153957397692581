import React, { Component } from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Breadcrumb from "../components/Breadcrumb"

import { isLoggedIn, getUser } from "../services/auth"
import { isBrowser } from "../helpers"

import AccountHeader from "../components/Account/AccountHeader"
import AccountNavWrap from "../components/Account/AccountNavWrap"

class Page extends Component {
  constructor(props) {
    super(props)

    this.state = {
      authenticated: false,
    }

    isBrowser && this.handleAuthentication()
  }

  async handleAuthentication() {
    const response = await isLoggedIn()

    if (response.data.errors) {
      navigate("/login")
    } else {
      this.setState({
        authenticated: true,
      })
    }
  }

  render() {
    if (!this.state.authenticated) {
      return null
    }

    const { firstName, lastName, login } = getUser()

    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: "/course-enquiry/",
        }}
        path={"a"}
        cta={"a"}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="page"
          current={{
            title: "Account",
            slug: "account",
            uri: "/account",
          }}
        />
        <AccountHeader name={`${firstName} ${lastName}`} email={`${login}`} />
        <AccountNavWrap />
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Page
