export default theme => ({
    accountPriceList: {
        position: 'relative',
        backgroundColor: 'white',
        '& .content': {
            padding: 32,
            [theme.breakpoints.up('sm')]: {
                borderLeft: '4px solid ' + theme.palette.secondary.main,
            },
            [theme.breakpoints.up('md')]: {
                padding: '50px 100px',
            },
            '& .price-list-wrap': {
                [theme.breakpoints.up('sm')]: {
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                },
                '& > div': {
                    [theme.breakpoints.up('sm')]: {
                        maxWidth: 290,
                    },
                    '& h3': {
                        fontWeight: '200',
                    }
                },
                '& > a': {
                    [theme.breakpoints.up('sm')]: {
                        maxWidth: 290,
                    },
                    backgroundColor: theme.palette.secondary.main,
                    padding: 24,
                    display: 'block',
                    color: theme.palette.secondary.contrastText,
                    position: 'relative',
                    transition: 'background-color .3s ease-in-out',
                    fontSize: 0,
                    '&:hover, &:focus': {
                        backgroundColor: theme.palette.primary.main,
                        '& span': {
                            backgroundColor: theme.palette.primary.main,
                        }
                    },
                    '& img': {
                        margin: 0,
                    },
                    '& span': {
                        backgroundColor: theme.palette.secondary.main,
                        fontFamily: theme.headerFont,
                        color: theme.palette.secondary.contrastText,
                        fontSize: 18,
                        letterSpacing: '0.016em',
                        position: 'absolute',
                        bottom: 23,
                        right: 75,
                        left: 24,
                        transition: 'background-color .3s ease-in-out',
                        padding: '10px 12px 5px 5px',
                        clipPath: 'polygon(0% 0%, calc(100% - 32px) 0, 100% 40px, 100% 100%, 0 100%)',
                    },
                    '& > svg': {
                        width: 36,
                        height: 36,
                        position: 'absolute',
                        bottom: 26,
                        right: 32,
                        filter: "brightness(0) invert(1)",
                    }
                }
            }
        }
    },
})