import active from "../../../images/account-active.png";

export default theme => ({
    accountNavWrapper: {
        position: 'relative',
        filter: 'drop-shadow(0 3px 6px rgba(0,0,0,0.16))',
        '& .account-nav': {
            backgroundColor: 'white',
            position: 'relative',
            zIndex: 2,
            // boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
            [theme.breakpoints.up('sm')]: {
                padding: 32,
            },
            '& button': {
                backgroundColor: 'transparent',
                fontFamily: 'Roboto Condensed',
                fontSize: 18,
                color: theme.palette.primary.main,
                border: 'none',
                padding: 12,
                borderBottom: '1px solid #727C8E1A',
                display: 'block',
                width: '100%',
                textAlign: 'left',
                '&[open]': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                },
                '&:nth-of-type(3n)': {
                    borderBottom: 'none',
                },
                [theme.breakpoints.up('sm')]: {
                    padding: '16px 0',
                    backgroundColor: 'white !important',
                    position: 'relative',
                    '&[open]': {
                        color: theme.palette.secondary.main,
                        '&::before': {
                            right: -30,
                        },
                        '&::after': {
                            right: -47,
                        }
                    },
                    '&::before': {
                        content: '""',
                        borderRight: '3px solid ' + theme.palette.secondary.main,
                        borderBottom: '3px solid ' + theme.palette.secondary.main,
                        display: 'block',
                        width: 12,
                        height: 12,
                        margin: '0 auto',
                        transformOrigin: '50%',
                        transform: 'rotate(-45deg)',
                        position: 'absolute',
                        right: 0,
                        top: 'calc(50% - 6px)',
                    },
                    '&::after': {
                        content: '""',
                        width: 15,
                        height: 54,
                        display: 'block',
                        backgroundImage: 'url(' + active + ')',
                        position: 'absolute',
                        backgroundPosition: 'center right',
                        right: 0,
                        top: 'calc(50% - 27px)',
                        zIndex: -1,
                    },
                },
            }
        },
        '& .page-content': {
            marginBottom: 50,
            [theme.breakpoints.up('md')]: {
                marginBottom: 120,
            },
            '& > div': {
                display: 'none',
                '&[open]': {
                    display: 'block',
                }
            }
        }
    }
})