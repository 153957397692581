import React from "react";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";
// import Link from "../../Link";

import { CoreHeadingBlock } from "../../../components/blocks/CoreHeadingBlock";
import { CoreParagraphBlock } from "../../../components/blocks/CoreParagraphBlock";

import image from "../../../images/PriceListCover.jpg";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme) => styles(theme));

export default function AccountPriceList(props) {
  const classes = useStyles();

  const { list } = props;

  return (
    <div className={`account-prices ${classes.accountPriceList}`}>
      <div className="content">
        <CoreHeadingBlock
          attributes={{
            align: "",
            anchor: "",
            className: "",
            content: "Price List",
            level: 2,
            textColor: "primary",
            backgroundColor: "",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
        <div className="price-list-wrap">
          <div>
            <CoreHeadingBlock
              attributes={{
                align: "",
                anchor: "",
                className: "",
                content: "Your price list is here",
                level: 3,
                textColor: "primary",
                backgroundColor: "",
                __typename: "WpCoreHeadingBlockAttributes",
              }}
              innerBlocks={[]}
            />
            <CoreParagraphBlock
              attributes={{
                align: "",
                anchor: "",
                className: "",
                content:
                  "Prices may change from time to time, so make sure you check back regularly to see the latest prices.",
                textColor: "primary",
                backgroundColor: "",
                __typename: "WpCoreParagraphBlockAttributes",
              }}
              innerBlocks={[]}
            />
          </div>
          <a href={list}>
            <img src={image} alt={``} />
            <span>Download Price List</span>
            <GetAppIcon />
          </a>
        </div>
      </div>
      <div className="image-link"></div>
    </div>
  );
}
