import React from "react";
import Grid from "@material-ui/core/Grid";

import ProductCardItem from "../../Product/ProductCardItem";
import { AddToComparisionButton } from "./Buttons";

class ProductsGrid extends React.Component {
  render() {
    if (!this.props.products) return null;

    const products = this.props.products;

    return (
      <Grid container spacing={3}>
        {products.map((product, index) => {
          return (
            <Grid
              key={index}
              item
              xs={this.props.xs ? this.props.xs : 12}
              sm={this.props.sm ? this.props.sm : 6}
              md={this.props.md ? this.props.md : 4}
            >
              <AddToComparisionButton product={product}>
                Compare
              </AddToComparisionButton>

              <ProductCardItem
                uri={product.link}
                productGallery={product.product.productGallery}
                // default={props.default}
                code={product.product.code}
                title={product.title}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

export default ProductsGrid;
