export default theme => ({
    accountOffers: {
        position: 'relative',
        backgroundColor: 'white',
        '& .content': {
            padding: 32,
            [theme.breakpoints.up('sm')]: {
                borderLeft: '4px solid ' + theme.palette.secondary.main,
            },
            [theme.breakpoints.up('md')]: {
                padding: '50px 100px',
            },
        }
    },
})